import RestApi, { virtualMuseumServiceBaseUrl } from '../config/api_config'

export default {
  created () {},
  methods: {
      VirtualMuseumDropdownsExternal () {
          RestApi.getData(virtualMuseumServiceBaseUrl, 'common-dropdowns', null).then(response => {
              if (response.success) {
                  this.$store.commit('VirtualMuseum/mutateVirtualMuseumCommonProperties', {
                      hasDropdownLoaded: true,
                      informationTypeList: response.data.informationTypeList,
                      cropTypeList: response.data.cropTypeList,
                      cropList: response.data.cropList,
                      characteristicsTypeList: response.data.characteristicsTypeList,
                      characteristicsList: response.data.characteristicsList,
                      soilTypeList: response.data.informationTypeList,
                      insectList: response.data.insectList,
                      diseaseList: response.data.diseaseList,
                      tourOperator: response.data.tourOperator,
                      pestList: response.data.pestList
                  })
                  this.$store.dispatch('VirtualMuseum/localizeVirtualMuseumDropdown', { value: this.$i18n.locale })
              }
          })
      }
  }
}

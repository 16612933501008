<template>
  <div :class="activeFont">
    <Customizer @onLogo="changeLogo" @toggle="sidebarMini" @animationChange="routerAnimationChange" />
    <div class="wrapper">
      <!-- Sidebar  -->
      <SmallSidebar v-if="$route.name === 'dashboard.home-5'" :logo="SmallSidebarLogo" @toggle="sidebarMini" />
      <Sidebar :items="verticalMenu" :logo="logo" :onlyLogo="onlyLogo" :onlyLogoText="onlyLogoText" @toggle="sidebarMini" :toggleButton="toggleSideIcon" :sidebarGroupTitle="sidebarGroupTitle" />
      <!-- TOP Nav Bar -->
      <DefaultNavBar :homeURL="{ name: 'external-user.common_dashboard' }" :sidebarGroupTitle="sidebarGroupTitle" @toggle="sidebarMini" :logo="logo">
        <template slot="responsiveRight">
          <ul class="navbar-nav ml-auto navbar-list">
            <li class="nav-item d-flex" v-nav-toggle>
              <lang-button/>
            </li>
            <li class="nav-item" v-nav-toggle>
              <a href="#" class="search-toggle iq-waves-effect" v-if="$store.state.Auth.activeRoleId !== 1">
                <i class="ri-notification-line"></i>
                <span class="bg-danger dots"></span>
              </a>
              <NotificationExternal ref='eotificationExternal' :component_id="componentId"/>
            </li>
          </ul>
        </template>
        <template slot="right">
          <ul class="navbar-list profile-tmp-img">
            <li class="" v-nav-toggle>
              <a href="#" class="search-toggle iq-waves-effect d-flex align-items-center rounded">
                <slot v-if="isLoad">
                  <slot v-if="authUser.user_type_id === 8 || authUser.user_type_id === 9">
                    <slot v-if="dataExternalUser.trainerOrTrainee">
                      <slot v-if="typeof dataExternalUser.trainerOrTrainee.image === 'undefined'">
                        <img :src="'/images/user_icon.png'" class="img-fluid rounded mr-1" alt="user">
                      </slot>
                      <slot v-else>
                        <slot v-if="dataExternalUser.trainerOrTrainee.image">
                          <img :src="trainingElearningServiceBaseUrl + 'storage/' + dataExternalUser.trainerOrTrainee.image" class="img-fluid rounded mr-1" alt="user">
                        </slot>
                        <slot v-else>
                          <img :src="'/images/user_icon.png'" class="img-fluid rounded mr-1" alt="user">
                        </slot>
                      </slot>
                    </slot>
                    <slot v-else>
                      <img :src="'/images/user_icon.png'" class="img-fluid rounded mr-1" alt="user">
                    </slot>
                  </slot>
                  <slot v-else>
                    <slot v-if="dataTest.farmerBasicInfo">
                      <slot v-if="typeof dataTest.farmerBasicInfo.attachment === 'undefined'">
                        <img :src="'/images/user_icon.png'" class="img-fluid rounded mr-1" alt="user">
                      </slot>
                      <slot v-else>
                        <slot v-if="dataTest.farmerBasicInfo.status == 1">
                          <img :src="'/images/user_icon.png'" class="img-fluid rounded mr-1" alt="user">
                        </slot>
                        <slot v-else>
                          <img :src="irriSchemeServiceBaseUrl + 'download-attachment?file=uploads/farmer-basic/original/' + dataTest.farmerBasicInfo.attachment" class="img-fluid rounded mr-1" alt="user">
                        </slot>
                      </slot>
                    </slot>
                    <slot v-else>
                      <img :src="'/images/user_icon.png'" class="img-fluid rounded mr-1" alt="user">
                    </slot>
                  </slot>
                </slot>
                <div class="caption">
                  <h6 class="mb-0 line-height text-white">{{ getLimitedAuthUserName(authUser.name) }}</h6>
                </div>
              </a>
              <div class="iq-sub-dropdown iq-user-dropdown">
                <div class="iq-card shadow-none m-0">
                  <div class="iq-card-body p-0 ">
                    <div class="bg-info p-3">
                      <h5 class="mb-0 text-white line-height">{{ getLimitedAuthUserName(authUser.name) }}</h5>
                    </div>
                    <router-link :to="{ name: 'external-user.site_preference' }" class="iq-sub-card iq-bg-primary-hover">
                      <div class="media align-items-center">
                        <div class="rounded iq-card-icon iq-bg-primary">
                          <i class="ri-account-box-line"></i>
                        </div>
                        <div class="media-body ml-3">
                          <h6 class="mb-0 ">{{ $t('nav.user.siteSettingTitle') }}</h6>
                          <p class="mb-0 font-size-12">{{ $t('nav.user.siteSettingSubTitle') }}</p>
                        </div>
                      </div>
                    </router-link>
                    <!-- <router-link :to="{ name: 'external-user.change_mobile' }" class="iq-sub-card iq-bg-primary-hover">
                      <div class="media align-items-center">
                        <div class="rounded iq-card-icon iq-bg-primary">
                          <i class="ri-account-box-line"></i>
                        </div>
                        <div class="media-body ml-3">
                          <h6 class="mb-0 ">{{ $t('nav.user.change_mobile') }}</h6>
                          <p class="mb-0 font-size-12">{{ $t('nav.user.changeMobileTitle') }}</p>
                        </div>
                      </div>
                    </router-link> -->
                    <router-link :to="{ name: 'external-user.password_change'}" class="iq-sub-card iq-bg-primary-hover">
                      <div class="media align-items-center">
                        <div class="rounded iq-card-icon iq-bg-primary">
                          <i class="ri-lock-unlock-fill"></i>
                        </div>
                        <div class="media-body ml-3">
                          <h6 class="mb-0 ">{{ $t('nav.user.accountChangePasswordTitle') }}</h6>
                          <p class="mb-0 font-size-12">{{ $t('nav.user.accountChangePasswordSub') }}</p>
                        </div>
                      </div>
                    </router-link>
                    <div class="d-inline-block w-100 text-center p-3">
                      <a class="bg-primary iq-sign-btn" href="javascript:void(0)" @click="logout" role="button">{{ $t('nav.user.signout') }}<i class="ri-login-box-line ml-2"></i></a>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </template>
      </DefaultNavBar>
      <!-- TOP Nav Bar END -->
      <div id="content-page" class="content-page">
        <b-container fluid="" v-if="!notBookmarkRouts.includes($route.name)">
        </b-container>
        <ExternalPanelBox v-show="$route.name !== 'external-user.common_dashboard'"/>
        <transition name="router-anim" :enter-active-class="`animated ${animated.enter}`" mode="out-in"
                    :leave-active-class="`animated ${animated.exit}`">
          <router-view/>
        </transition>
      </div>
    </div>
    <PushNotification :component_id="componentId" />
    <LayoutFooter />
  </div>
</template>
<script>
import RestApi, {
  incentiveGrantServiceBaseUrl,
  irriSchemeServiceBaseUrl,
  trainingElearningServiceBaseUrl,
  seedFertilizerServiceBaseUrl,
  authServiceBaseUrl,
  agriMarketingServiceBaseUrl,
  licenseRegistrationServiceBaseUrl,
  agriResearchServiceBaseUrl,
  warehouseServiceBaseUrl,
  virtualMuseumServiceBaseUrl
} from '@/config/api_config'
import { core } from '../config/pluginInit'
import { mapGetters } from 'vuex'
import { snakeToWords } from '@/Utils/fliter'
import Sidebar from '../components/core/sidebars/SidebarExternal'
import DefaultNavBar from '../components/core/navbars/DefaultNavBar'
import SideBarItems from '../FackApi/json/SideBarExternalUser'
import profile from '../assets/images/user/user-1.jpeg'
import Logo from '../assets/images/logo.png'
import Customizer from './Components/Customizer'
import NotificationExternal from './Components/NotificationExternal'
import SmallSidebar from '../components/core/sidebars/SmallSidebar'
import LayoutFooter from './Components/LayoutFooter'
import ExternalCommonDropdown from '@/mixins/dropdown-exteral-dashboard'
import ExternalUserAgriMarketing from '@/mixins/dropdown-external-user-agri-marketing'
import ExternalTrainingELearning from '@/mixins/dropdown-external-training-e-learning'
import ExternalPanelBox from '@/layouts/Components/ExternalPanelBox'
import ExternalIncentiveFarmer from '@/mixins/dropdown-external-incentive-farmer'
import ExternalLrcpn from '@/mixins/dropdown-external-lrcpn'
import ExternalAgriResearch from '@/mixins/external-agri-research.js'
import VirtualMuseumDropdownMixin from '@/mixins/dropdown-external-virtual-museum'
import PushNotification from './Components/PushNotification'
import { EventBus } from '@/EventBusLayout'
import LangButton from './Components/LangButton'
import logout from '@/Utils/logout'

export default {
  name: 'VerticleLayout',
  mixins: [VirtualMuseumDropdownMixin, ExternalAgriResearch, ExternalCommonDropdown, ExternalUserAgriMarketing, ExternalTrainingELearning, ExternalIncentiveFarmer, ExternalLrcpn],
  // mixins: [ExternalCommonDropdown, ExternalLrcpn],
  components: {
    ExternalPanelBox,
    LayoutFooter,
    Customizer,
    NotificationExternal,
    Sidebar,
    DefaultNavBar,
    SmallSidebar,
    PushNotification,
    LangButton
  },
  beforeMount () {
    EventBus.$on('EXTERNAL_USER_SIDEBAR_MENUS', this.setSideBarMenus)
    // EventBus.$on('menuUpdate', this.checkTrainingUser)
    EventBus.$on('INCENTIVE_FARMER_MENU_UPDATE', this.checkIncentiveFarmerProfile)
    EventBus.$on('LRCPN_MENU_UPDATE', this.checkLrcpnUser)
    EventBus.$on('EXTERNAL_USER_COMMON_PROFILE', this.getCommonProfileData)
    EventBus.$on('RESEARCH_FARMER', this.checkResearchFarmer)
    // EventBus.$on('dealerUpdate', this.dealerEnable)
    EventBus.$on('REDIRECT_TO_SERVICE_URL', this.redirectToServiceUrl)
  },
  beforeDestroy () {
    EventBus.$on('INCENTIVE_FARMER_MENU_UPDATE', this.checkIncentiveFarmerProfile)
    // EventBus.$off('menuUpdate', this.checkTrainingUser)
    // EventBus.$off('dealerUpdate', this.dealerEnable)
  },
  mounted () {
    if (window.performance) {
      this.$store.dispatch('mutateCommonProperties', { hasDropdownLoaded: false })
    }
  },
  created () {
    this.getCommonProfileData()
    this.verticalMenu = []
    if (this.authUser === null) {
      this.logout()
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser',
      panelObj: 'ExternalUserIrrigation/panelObj'
    }),
    dataTest () {
      return this.$store.state.ExternalUserIrrigation
    },
    dataExternalUser () {
      return this.$store.state.ExternalUserTraining
    },
    toggleSideIcon () {
      let show = true
      switch (this.$route.name) {
        case 'dashboard.home-3':
          show = false
          break
      }
      return show
    },
    currentLocale () {
      return this.$i18n.locale
    }
  },
  data () {
    return {
      componentId: 0,
      langLabel: this.$i18n.locale === 'bn' ? this.$t('globalTrans.en') : this.$t('globalTrans.bn'),
      animated: { enter: 'fadeInUp', exit: 'fadeOut' },
      verticalMenu: [],
      userProfile: profile,
      onlyLogo: true,
      onlyLogoText: false,
      sidebarGroupTitle: true,
      logo: Logo,
      SmallSidebarLogo: Logo,
      isLoad: false,
      irriSchemeServiceBaseUrl: irriSchemeServiceBaseUrl,
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      notBookmarkRouts: [],
      baseUrl: {
        2: licenseRegistrationServiceBaseUrl,
        3: agriResearchServiceBaseUrl,
        4: incentiveGrantServiceBaseUrl,
        5: agriMarketingServiceBaseUrl,
        6: warehouseServiceBaseUrl,
        7: virtualMuseumServiceBaseUrl,
        8: seedFertilizerServiceBaseUrl,
        9: irriSchemeServiceBaseUrl,
        10: trainingElearningServiceBaseUrl
      }
    }
  },
  watch: {
    currentLocale: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$store.commit('ExternalUserIrrigation/localizeExternalDropdown', { value: newVal })
        this.$store.dispatch('ExternalLrcpn/localizeExternalLrcpnDropdown', { value: newVal })
      }
    }
  },
  methods: {
    async setSideBarMenus (menus) {
      this.verticalMenu = menus
      const activePanel = this.$store.state.ExternalUserIrrigation.activePanel
      this.$store.dispatch('setNotificationList', [])
      this.$store.dispatch('setTotalNotification', 0)
      if (activePanel === 'trainee_panel') {
        this.componentId = 10
        this.checkTrainingUser()
      } else if (activePanel === 'dealer_panel') {
        this.componentId = 8
        this.checkDealerUser()
      } else if (activePanel === 'incentive_farmer') {
        this.componentId = 4
        this.checkIncentiveFarmerProfile()
      } else if (activePanel === 'grant_panel') {
        this.componentId = 4
        this.checkGrantPanelUser()
      } else if (activePanel === 'virtual_museum') {
        this.componentId = 7
        this.redirectToServiceUrl()
      } else if (activePanel === 'ginner' || activePanel === 'grower') {
        this.componentId = 5
        this.checkGinnerGrowerProfile(activePanel)
      } else if (activePanel === 'divisional_head') {
        this.componentId = 5
        this.checkDivisionalHead()
      } else if (activePanel === 'irrigation') {
        this.componentId = 9
        this.checkIrrigationUser()
      } else if (activePanel === 'lrcpn') {
        this.componentId = 2
        this.checkLrcpnUser()
      } else if (activePanel === 'research_farmer') {
        this.componentId = 3
        this.checkResearchFarmer()
      } else if (activePanel === 'warehouse_farmers') {
        this.componentId = 6
        this.redirectToServiceUrl()
      } else if (activePanel === 'supplier') {
        this.componentId = 11
        this.redirectToServiceUrl()
      }
      if (this.componentId > 0) {
        this.getNotifications()
      }
    },
    getNotifications () {
      const baseUrl = this.baseUrl[this.componentId]
      if (this.componentId > 0) {
        RestApi.getData(baseUrl, '/notification-sender/notification').then(response => {
          if (response.data.data.length > 0) {
              this.$store.dispatch('setNotificationList', response.data.data)
              this.$store.dispatch('setTotalNotification', response.total)
              // this.totalNotification = response.total
          } else {
              this.$store.dispatch('setNotificationList', [])
              this.$store.dispatch('setTotalNotification', 0)
              // this.totalNotification = 0
          }
        })
      }
    },
    redirectToServiceUrl (permitted = true) {
      if (permitted) {
        const serviceUrl = this.$store.state.Portal.serviceUrl.externalPanelServiceUrl
        if (serviceUrl && serviceUrl !== undefined) {
          this.$store.dispatch('Portal/setServiceUrl', { externalPanelServiceUrl: '' })
          this.$router.push(serviceUrl)
        }
      } else {
        // redirect to targeted url case of portal service url
          const serviceUrl = this.$store.state.Portal.serviceUrl.externalPanelServiceUrl
          if (serviceUrl && serviceUrl !== undefined) {
            const dashboardRoute = this.verticalMenu[0].link.name
            this.$router.push({ name: dashboardRoute })
          }
      }
    },
    trainingMenuActive () {
      const activePanel = this.$store.state.ExternalUserIrrigation.activePanel
      const trainingType = this.$store.state.ExternalUserIrrigation.panelObj.trainingType
      if (activePanel !== 'trainee_panel') {
        return
      }
      const menuSort = []
      if (trainingType === 2) {
        this.verticalMenu.forEach((item, key) => {
          if (!(item.title === 'Evaluation' || item.title === 'Hand Note' || item.title === 'Invitation' || item.title === 'Assigned Driver' || item.title === 'Speaker Honorarium Sheet')) {
            const data = item
            data.class_name = ''
            menuSort.push(data)
          }
        })
      } else {
        this.verticalMenu.forEach((item, key) => {
          if (!(item.title === 'Trainer Evaluation' || item.title === 'Online Course Evaluation Management' || item.title === 'Evaluation Management' || item.title === 'Circular List' || item.title === 'Attendance Management' || item.title === 'ID Card' || item.title === 'Training Course Evaluation' || item.title === 'Trainee Hand Note' || item.title === 'Certificate List' || item.title === 'Release Order List' || item.title === 'Request For Document' || item.title === 'Bill Recive' || item.title === 'Pre-Post Evaluation' || item.title === 'Pre-Post Evaluation Result')) {
            const data = item
            data.class_name = ''
            menuSort.push(data)
          }
        })
      }
      this.verticalMenu = menuSort
    },
    trainingMenuDisabled () {
      /** registrationFor 2 is trainee */
      const trainingType = this.$store.state.ExternalUserIrrigation.panelObj.trainingType
      const activePanel = this.$store.state.ExternalUserIrrigation.activePanel
      if (activePanel !== 'trainee_panel') {
        return
      }
      const menuSort = []
      if (trainingType === 2) {
        this.verticalMenu.forEach((item, key) => {
          if (!(item.title === 'Evaluation' || item.title === 'Hand Note' || item.title === 'Invitation' || item.title === 'Assigned Driver' || item.title === 'Speaker Honorarium Sheet')) {
            const data = item
            data.class_name = ''
            menuSort.push(data)
          }
        })
      } else {
        this.verticalMenu.forEach((item, key) => {
          if (!(item.title === 'Trainer Evaluation' || item.title === 'Online Course Evaluation Management' || item.title === 'Evaluation Management' || item.title === 'Circular List' || item.title === 'Attendance Management' || item.title === 'ID Card' || item.title === 'Training Course Evaluation' || item.title === 'Trainee Hand Note' || item.title === 'Certificate List' || item.title === 'Release Order List' || item.title === 'Request For Document' || item.title === 'Bill Recive' || item.title === 'Pre-Post Evaluation' || item.title === 'Pre-Post Evaluation Result')) {
            const data = item
            data.class_name = ''
            menuSort.push(data)
          }
        })
      }
      this.verticalMenu = menuSort
    },
    dealerMenuToggle (isEnable = true) {
      const activePanel = this.$store.state.ExternalUserIrrigation.activePanel
      if (activePanel !== 'dealer_panel') {
          return
      }
      const menuSort = []
      this.verticalMenu.forEach((item, key) => {
        const data = item
        data.class_name = isEnable ? '' : 'btn disabled'
        menuSort.push(data)
      })
      this.verticalMenu = menuSort
    },
    checkTrainingUser () {
      this.trainingMenuDisabled(2)
      this.$store.commit('ExternalUserIrrigation/setPanelObj', {
        trainingLoader: true,
        trainingMessage: this.$t('externalPanel.checking_profile')
      })
      const appliedOrg = {
        applied_org_id: this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg,
        applied_type: this.$store.state.ExternalUserIrrigation.panelObj.applied_type
      }
      RestApi.getData(trainingElearningServiceBaseUrl, 'personal-infos/check-user', appliedOrg).then(response => {
        if (response.success) {
          this.$store.dispatch('ExternalUserTraining/getTrainerOrTraineeDetails', response.data)
          let status = 1
          if (parseInt(this.$store.state.ExternalUserIrrigation.panelObj.trainingType) === 2) {
            if (response.data.status === 3) {
              this.trainingMenuActive()
              status = 3
            } else if (response.data.status === 2) {
              status = 2
              this.trainingMenuDisabled()
            } else if (response.data.status === 1) {
              status = 1
              this.trainingMenuDisabled()
            }
          } else if (this.$store.state.ExternalUserIrrigation.panelObj.trainingType === 1) {
            if (response.data.trainer_status === 3) {
              this.trainingMenuActive()
              status = 3
            } else if (response.data.trainer_status === 2) {
              status = 2
              this.trainingMenuDisabled()
            } else if (response.data.trainer_status === 1) {
              status = 1
              this.trainingMenuDisabled()
            }
          }
          this.$store.commit('ExternalUserIrrigation/setPanelObj', {
            trainingLoader: false,
            profileStatus: status,
            trainingMessage: ''
          })

          // redirected to service url
          const serviceUrl = this.$store.state.Portal.serviceUrl.externalPanelServiceUrl
          if (serviceUrl && serviceUrl !== undefined) {
            if (status === 3) {
              this.$router.push(serviceUrl)
            } else {
              this.$router.push('/trainee-trainer-panel/profile')
            }
          }
        } else {
          this.$store.commit('ExternalUserIrrigation/setPanelObj', {
            trainingLoader: false,
            profileStatus: 0,
            trainingMessage: this.$t('externalTraining.dashboard_message')
          })
          this.$store.dispatch('ExternalUserTraining/getTrainerOrTraineeDetails', '')

          // redirected to service url
          const serviceUrl = this.$store.state.Portal.serviceUrl.externalPanelServiceUrl
          if (serviceUrl && serviceUrl !== undefined) {
              this.$router.push('/trainee-trainer-panel/profile')
          }
        }
        this.isLoad = true
      })
    },
    checkDealerUser () {
      this.dealerMenuToggle(false)
      this.$store.commit('ExternalUserIrrigation/setPanelObj', {
        dealerLoader: true,
        dealerMessage: this.$t('externalPanel.checking_profile')
      })
      RestApi.getData(seedFertilizerServiceBaseUrl, 'dealer-panel/profile').then(response => {
        if (response.success) {
          this.dealerMenuToggle()
          this.$store.commit('ExternalUserIrrigation/setPanelObj', {
            dealerLoader: false,
            dealerMessage: ''
          })
          this.redirectToServiceUrl()
        } else {
          this.$store.commit('ExternalUserIrrigation/setPanelObj', {
            dealerLoader: false,
            dealerMessage: this.$t('externalPanel.unauthorized_message')
          })
          this.redirectToServiceUrl(false)
        }
      })
    },
    checkIncentiveFarmerProfile () {
      this.toggleIncentiveFarmerMenu(false)
      this.$store.commit('ExternalUserIrrigation/setPanelObj', {
        incentiveFarmerLoader: true,
        incentiveFarmerMesage: this.$t('externalPanel.checking_profile')
      })
      RestApi.getData(incentiveGrantServiceBaseUrl, 'external-user/profile-info/show').then(response => {
        if (response.success && response.data.save_status === 2) {
          this.toggleIncentiveFarmerMenu()
          this.$store.commit('ExternalUserIrrigation/setPanelObj', {
            incentiveFarmerLoader: false,
            incentiveFarmerMesage: '',
            profileStatus: 4
          })
          this.redirectToServiceUrl()
        } else {
          this.toggleIncentiveFarmerMenu(false)
          this.$store.commit('ExternalUserIrrigation/setPanelObj', {
            incentiveFarmerLoader: false,
            incentiveFarmerMesage: '',
            profileStatus: 0
          })
          this.redirectToServiceUrl(false)
        }
        this.isLoad = true
      })
    },
    checkGrantPanelUser () {
      this.toggleGrantPanel(false)
      this.$store.commit('ExternalUserIrrigation/setPanelObj', {
        irriUserLoader: true,
        irriUserStatus: 0
      })
      RestApi.getData(incentiveGrantServiceBaseUrl, `barc/applicant-panel/applicant/exist-profile-check?user_id=${this.authUser.user_id}`).then(response => {
        let profileStatus = 0
        if (response.success) {
          this.toggleGrantPanel(true)
          profileStatus = response.status
          this.$store.dispatch('ExternalUserIrrigation/getFarmerBasicInfoDetails', response.data)
          this.redirectToServiceUrl()
        } else {
          this.toggleGrantPanel(false)
          this.$store.commit('ExternalUserIrrigation/setPanelObj', {
            irriUserLoader: false,
            irriUserStatus: profileStatus
          })
          this.redirectToServiceUrl(false)
        }
      })
    },
    checkIrrigationUser () {
      this.$store.commit('ExternalUserIrrigation/setPanelObj', {
        irriUserLoader: true,
        irriUserStatus: 0
      })
      RestApi.getData(irriSchemeServiceBaseUrl, 'farmer-basic-infos/check-user?with_data=' + false).then(response => {
        let profileStatus = 0
        if (response.success) {
          profileStatus = response.data.status === 2 ? 4 : 0
          this.$store.dispatch('ExternalUserIrrigation/getFarmerBasicInfoDetails', response.data)
          this.redirectToServiceUrl()
        }
        this.$store.commit('ExternalUserIrrigation/setPanelObj', {
          irriUserLoader: false,
          irriUserStatus: profileStatus
        })
        this.redirectToServiceUrl(false)
      })
    },
    checkLrcpnUser () {
      this.toggleLrcpnMenu(true)
      // this.$store.commit('ExternalUserIrrigation/setPanelObj', {
      //   lrcpnUserLoader: true,
      //   profileStatus: 0
      // })
      const params = { mobile_no: this.authUser.mobile_no, user_id: this.authUser.id }
      RestApi.getData(licenseRegistrationServiceBaseUrl, 'external-panel/profile/details', params)
      .then(response => {
        let profileStatus = 0
        if (response.success) {
          profileStatus = response.data.status === 2 ? 2 : 1
          if (profileStatus === 2) {
            const labels = []
            Object.keys(response.data).forEach((key, value) => {
              const obj = {}
              if (key === 'photo') {
                const fieldName = 'field_name'
                obj[fieldName] = key
                const fieldType = 'field_type'
                obj[fieldType] = 'Attachment'
                const labelName = 'label_name'
                obj[labelName] = snakeToWords(key)
                const labelValue = 'value'
                obj[labelValue] = value
                labels.push(obj)
              } else if (key === 'region' || key === 'gender' || key === 'division_id' || key === 'district_id' || key === 'upazilla_id' || key === 'status') {
                const fieldName = 'field_name'
                obj[fieldName] = key
                const fieldType = 'field_type'
                obj[fieldType] = 'Dropdown'
                const labelName = 'label_name'
                obj[labelName] = snakeToWords(key)
                const labelValue = 'value'
                obj[labelValue] = value
                labels.push(obj)
              } else if (key === 'present_address' || key === 'permanent_address') {
                const fieldName = 'field_name'
                obj[fieldName] = key
                const fieldType = 'field_type'
                obj[fieldType] = 'Paragraph'
                const labelName = 'label_name'
                obj[labelName] = snakeToWords(key)
                const labelValue = 'value'
                obj[labelValue] = value
                labels.push(obj)
              } else if (key === 'dob') {
                const fieldName = 'field_name'
                obj[fieldName] = key
                const fieldType = 'field_type'
                obj[fieldType] = 'Date'
                const labelName = 'label_name'
                obj[labelName] = snakeToWords(key)
                const labelValue = 'value'
                obj[labelValue] = value
                labels.push(obj)
              } else {
                const fieldName = 'field_name'
                obj[fieldName] = key
                const fieldType = 'field_type'
                obj[fieldType] = 'InputField'
                const labelName = 'label_name'
                obj[labelName] = snakeToWords(key)
                const labelValue = 'value'
                obj[labelValue] = value
                labels.push(obj)
              }
            })
            this.$store.commit('ExternalLrcpn/setLrcpnUserlabel', labels)
            this.redirectToServiceUrl()
          }
          this.$store.commit('ExternalLrcpn/setLrcpnUserDetails', response.data)
        }
        this.redirectToServiceUrl(false)
        // if (profileStatus === 2) {
        //   this.toggleLrcpnMenu()
        // }
        // this.$store.commit('ExternalUserIrrigation/setPanelObj', {
        //   lrcpnUserLoader: false,
        //   profileStatus: profileStatus === 2 ? 4 : 0
        // })
      })
    },
    toggleIncentiveFarmerMenu (isEnable = true) {
      const activePanel = this.$store.state.ExternalUserIrrigation.activePanel
      if (activePanel !== 'incentive_farmer') {
          return
      }
      const menuArr = []
      SideBarItems.incentive_farmer.forEach((item, key) => {
        const data = item
          data.class_name = (isEnable || item.title === 'Dashboard' || item.title === 'Profile') ? '' : 'btn disabled'
          menuArr.push(data)
      })
      this.verticalMenu = menuArr
    },
    toggleGrantPanel (isEnable = true) {
      const activePanel = this.$store.state.ExternalUserIrrigation.activePanel
      if (activePanel !== 'grant_panel') {
          return
      }
      const menuArr = []
      SideBarItems.grant_panel.forEach((item, key) => {
        const data = item
          data.class_name = (isEnable || item.title === 'Dashboard' || item.title === 'Profile') ? '' : 'btn disabled'
          menuArr.push(data)
      })
      this.verticalMenu = menuArr
    },
    toggleLrcpnMenu (isEnable = true) {
      const activePanel = this.$store.state.ExternalUserIrrigation.activePanel
      if (activePanel !== 'lrcpn') {
          return
      }
      const menuArr = []
      SideBarItems.lrcpn.forEach((item, key) => {
        const data = item
          data.class_name = (isEnable || item.title === 'Dashboard' || item.title === 'Profile') ? '' : 'btn disabled'
          menuArr.push(data)
      })
      this.verticalMenu = menuArr
    },
    toggleGinnerMenu (enable = false) {
      const activePanel = this.$store.state.ExternalUserIrrigation.activePanel
      if (['ginner', 'grower', 'divisional_head'].indexOf(activePanel) === -1) {
        return
      }
      const menuSort = []
      this.verticalMenu.forEach((item, key) => {
        const data = item
        data.class_name = enable ? '' : 'btn disabled'
        menuSort.push(data)
      })
      this.verticalMenu = menuSort
    },
    farmerBasicInfo () {
      return true
    },
    checkUser () {
      RestApi.getData(irriSchemeServiceBaseUrl, 'farmer-basic-infos/check-user?with_data=' + false).then(response => {
        if (response.success) {
          this.$store.dispatch('ExternalUserIrrigation/getFarmerBasicInfoDetails', response.data)
        }
        this.isLoad = true
      })
    },
    checkGinnerGrowerProfile (panelName) {
      this.toggleGinnerMenu()
      this.$store.commit('ExternalUserIrrigation/setPanelObj', {
        ginnerGrowerLoader: true,
        ginnerGrowerMessage: this.$t('externalPanel.checking_profile')
      })
      const mobileNo = this.$store.state.Auth.authUser.mobile_no
      RestApi.getData(agriMarketingServiceBaseUrl, '/ginner-grower-profile/details' + '/' + mobileNo).then(response => {
        if (response.success && response.data.type === 1 && panelName === 'ginner') {
          this.toggleGinnerMenu(true)
          this.$store.commit('ExternalUserIrrigation/setPanelObj', {
            ginnerGrowerLoader: false,
            ginnerGrowerMessage: ''
          })
          this.redirectToServiceUrl()
        } else if (response.success && response.data.type === 2 && panelName === 'grower') {
          this.toggleGinnerMenu(true)
          this.$store.commit('ExternalUserIrrigation/setPanelObj', {
            ginnerGrowerLoader: false,
            ginnerGrowerMessage: ''
          })
          this.redirectToServiceUrl()
        } else {
          this.$store.commit('ExternalUserIrrigation/setPanelObj', {
            ginnerGrowerLoader: false,
            ginnerGrowerMessage: this.$t('externalPanel.unauthorized_message')
          })
          this.redirectToServiceUrl(false)
        }
      })
    },
    checkDivisionalHead () {
      this.toggleGinnerMenu(false)
      this.$store.commit('ExternalUserIrrigation/setPanelObj', {
        divisionalHeadLoader: true,
        divisionalHeadMessage: this.$t('externalPanel.checking_profile')
      })
      const mobileNo = this.$store.state.Auth.authUser.mobile_no
      RestApi.getData(agriMarketingServiceBaseUrl, '/e-pusti/divisional-head/checking/profile' + '/' + mobileNo).then(response => {
        if (response.success && response.data.status === 1) {
          this.toggleGinnerMenu(true)
          this.$store.commit('ExternalUserIrrigation/setPanelObj', {
            divisionalHeadLoader: false,
            divisionalHeadMessage: ''
          })
          this.redirectToServiceUrl()
        } else {
          this.$store.commit('ExternalUserIrrigation/setPanelObj', {
            divisionalHeadLoader: false,
            divisionalHeadMessage: this.$t('externalPanel.unauthorized_message')
          })
          this.redirectToServiceUrl(false)
        }
      })
    },
    toggleResearchFarmerMenu (isEnable = true) {
      const activePanel = this.$store.state.ExternalUserIrrigation.activePanel
      if (activePanel !== 'research_farmer') {
          return
      }
      const menuArr = []
      SideBarItems.research_farmer.forEach((item, key) => {
        const data = item
          data.class_name = (isEnable || item.title === 'My Profile') ? '' : 'btn disabled'
          menuArr.push(data)
      })
      this.verticalMenu = menuArr
    },
    checkResearchFarmer () {
      this.toggleResearchFarmerMenu(false)
      this.$store.commit('ExternalUserIrrigation/setPanelObj', {
        researchFarmerLoader: true,
        profileStatus: 4
      })
      RestApi.getData(agriResearchServiceBaseUrl, '/research-panel/profile?user_id=' + this.authUser.user_id).then(response => {
        if (response.success) {
          this.toggleResearchFarmerMenu(true)
          this.$store.commit('ExternalUserIrrigation/setPanelObj', {
            researchFarmerLoader: false,
            profileStatus: response.data.status === 1 ? 4 : 0
          })
          this.redirectToServiceUrl()
        } else {
          this.$store.commit('ExternalUserIrrigation/setPanelObj', {
            researchFarmerLoader: false,
            profileStatus: 0
          })
          this.redirectToServiceUrl(false)
        }
      })
    },
    changeLogo (e) {
      this.logo = e
    },
    sidebarMini () {
      core.triggerSet()
      this.$store.dispatch('Setting/miniSidebarAction')
    },
    logout () {
      // localStorage.setItem('logout', 'logout-' + Math.random())
      // this.$store.dispatch('Auth/updateAuthUser', null)
      this.$store.commit('ExternalUserIrrigation/mutateExternalCommonProperties', {
        hasDropdownLoaded: false
      })
      this.$store.dispatch('ExternalUserIrrigation/getFarmerBasicInfoDetails', null)
      logout()
      // this.$router.push('/auth/login')
    },
    routerAnimationChange (e) {
      this.animated = e
    },
    getLimitedAuthUserName (name) {
      const fullName = name.split(' ')
      const len = fullName.length
      let obtainedName = ''
      for (let i = 0; i < len; i++) {
        const part = obtainedName + ' ' + fullName[i]
        if (part.length <= 10) {
          obtainedName += fullName[i] + ' '
        }
      }
      if (obtainedName === '') {
        obtainedName = fullName[0].substring(0, 7) + '...'
      }
      return obtainedName.trim()
    },
    getCommonProfileData () {
      this.$store.dispatch('ExternalUserIrrigation/mutateCommonProfile', {
        father_name: null,
        father_name_bn: null,
        mother_name: null,
        mother_name_bn: null,
        mobile_no: null,
        nid: null,
        gender: 0
      })
      RestApi.getData(authServiceBaseUrl, '/external-user/common-profile').then(response => {
        let tmp = {
          user_id: this.authUser.user_id,
          username: this.authUser.username,
          name: this.authUser.name,
          name_bn: this.authUser.name_bn,
          mobile_no: this.authUser.mobile_no,
          email: this.authUser.email
        }
        if (response.success) {
          tmp = {
              name: response.data.name,
              name_bn: response.data.name_bn,
              father_name: response.data.father_name,
              father_name_bn: response.data.father_name_bn,
              mother_name: response.data.mother_name,
              mother_name_bn: response.data.mother_name_bn,
              nid: response.data.nid,
              gender: response.data.gender,
              mobile_no: this.authUser.mobile_no
          }
        }
        this.$store.dispatch('ExternalUserIrrigation/mutateCommonProfile', tmp)
      })
    }
  }
}
</script>
<style>
  @import url("../assets/css/custom.css");
</style>

<style scoped>
  .profile-tmp-img img {
    width: 48px;
  }
</style>

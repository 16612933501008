import RestApi, { commonServiceBaseUrl, incentiveGrantServiceBaseUrl } from '../config/api_config'

export default {
  created () {
    this.loadIncentiveCommonDropdown()
  },
  methods: {
    loadIncentiveCommonDropdown () {
      RestApi.getData(incentiveGrantServiceBaseUrl, 'common-dropdowns', null).then(response => {
        if (response.success) {
          this.$store.commit('ExternalIncentiveFarmer/mutateExternalIncentiveFarmerDropdown', {
            educationLevelList: response.data.educationLevelList,
            cropList: response.data.cropList,
            // villageList: response.data.villageList,
            circularList: response.data.circularList,
            instrumentList: response.data.instrumentList,
            supplierList: response.data.supplierList,
            // blockList: response.data.blockList,
            enlistedUniversityList: response.data.enlistedUniversityList,
            // seasonSetupList: response.data.seasonSetupList,
            projectList: response.data.projectList,
            agMaterialTypeList: response.data.agMaterialTypeList,
            macMachineTypeList: response.data.macMachineTypeList,
            macBrandList: response.data.macBrandList,
            agMaterialList: response.data.agMaterialList
          })
          this.$store.dispatch('ExternalIncentiveFarmer/localizeExternalIncentiveFarmerDropdown', { value: this.$i18n.locale })
        }
      })
    },
    loadCommonConfig () {
      RestApi.getData(commonServiceBaseUrl, 'external-common-config2-dropdowns', null).then(response => {
        if (response.success) {
          this.$store.commit('ExternalIncentiveFarmer/mutateDropdownCommonConfig', {
            bankList: response.data.bankList,
            branchList: response.data.branchList,
            cityCorporationList: response.data.cityCorporationList,
            pauroshobaList: response.data.pauroshobaList,
            wardList: response.data.wardList
          })
          this.$store.dispatch('ExternalIncentiveFarmer/localizeDropdownCommonConfig', { value: this.$i18n.locale })
        }
      })
    }
  }
}

import RestApi, { trainingElearningServiceBaseUrl } from '../config/api_config'

export default {
  computed: {
    hasDropdownLoadedTrainingElearningService () {
      return this.$store.state.ExternalUserTraining.static.hasDropdownLoaded
    }
  },
  // watch: {
  //   hasDropdownLoadedTrainingElearningService: function (newValue) {
  //     if (!newValue) {
  //       this.loadDropdownExternalTraining()
  //     }
  //   }
  // },
  created () {
    // const hasDropdownLoadedTrainingElearningService = this.$store.state.TrainingElearning.hasDropdownLoaded
    // if (window.performance) {
    //   this.loadDropdownExternalTraining()
    // }
  },
  methods: {
    loadDropdownExternalTraining () {
      RestApi.getData(trainingElearningServiceBaseUrl, 'common-dropdowns', null).then(response => {
        if (response.success) {
          this.$store.commit('ExternalUserTraining/mutateExternalTraneeTranierDropdown', {
            hasDropdownLoaded: true,
            roomTypeList: response.data.RoomTypeList,
            roomRentList: response.data.roomRentList,
            roomList: response.data.roomList,
            floorList: response.data.floorList,
            trainingTypeList: response.data.trainingTypeList,
            trainingCategoryList: response.data.trainingCategoryList,
            trainingTitleList: response.data.TrainingTitleList,
            courseList: response.data.courseList,
            courseDocumentSetupList: response.data.courseDocumentSetupList,
            trainerEvaluationList: response.data.trainerEvaluationList,
            // personalInfoList: response.data.personalInfoList,
            gradingStatus: response.data.gradingStatus,
            faqSetupList: response.data.faqSetupList,
            trainingExpertiseList: response.data.trainingExpertiseList,
            trainingReportHeadList: response.data.trainingReportHeadList,
            driverList: response.data.driverList,
            guestHouseList: response.data.guestHouseList,
            accomodationSetupList: response.data.accomodationSetupList
          })
          this.$store.dispatch('ExternalUserTraining/localizeExternalTraneeTranierDropdown', { value: this.$i18n.locale })
        }
      })
    }
  }
}
